.video_container {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.changed_height {
  max-height: 650px;

}


.fixed_height {
  height: 100%;
  position: relative;
  align-items: center;
}

.cols-1:nth-child(3){
  max-height: 1000px !important;
}

.canvas {
  height: 98%;
  background-color: rgb(30 35 41);
}

.tabcontent {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}


.alarm_item {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #ffffff;
    border-radius: 10px;
  }
}

#logout_button {
  box-shadow: 0px -1px 0px 0px #676766, -1px -1px 0px 0px #676766,
    -1px -1px 1px 0px #676766, -1px -1px 2px 0px #676766;
}

.cloud-camera-spinner {
  @apply relative;
}
.cloud-camera-spinner .cloud {
  @apply w-14;
}
.cloud-camera-spinner .camera {
  @apply absolute top-5 left-4;
}
.cloud-camera-spinner.loading .cloud {
  animation: linear swipe-animation 8s;
  animation-iteration-count: infinite;
}
.cloud-camera-spinner.loading .camera {
  @apply absolute top-5 left-4;
  animation: linear swipe-animation 6s;
  animation-iteration-count: infinite;
}


.alarm_item {
  border: 1px solid transparent;
}

.alarm_item:hover {
  border: 1px solid #ffffff;
  border-radius: 10px;
}

#logout_button {
  box-shadow: 0px -1px 0px 0px #676766, -1px -1px 0px 0px #676766,
    -1px -1px 1px 0px #676766, -1px -1px 2px 0px #676766;
}

.toast-message{
  color: rgb(204, 0, 0);
  background: rgba(255, 255, 255, 0.245);
  }

@keyframes swipe-animation {
  from {
    transform: rotateY(0deg);
  }
  from {
    transform: rotateY(360deg);
  }
}

.hover\:drop-shadow-primary:hover {
  filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
}
