@import url('https://unpkg.com/vis-timeline@latest/styles/vis-timeline-graph2d.min.css');

.current_time {
  background-color: rgb(11, 11, 12);
  height: 24px;
  color: rgb(230, 225, 219);
  font-size: 14px;
  padding: 0 5px 5px 5px;
  margin-bottom: 2px;
  text-align: center;
  align-content: center;
  font-weight: lighter;
  border: 1px solid white;
  border-radius: 5px;
}

.vis-timeline {
  position: relative;
  border: 0 solid white !important;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #161b1e !important;
  border-top-left-radius: 5px !important;
  width: 180px;
}

.vis-time-axis {
  transform-origin: 200px !important;
}

.vis-grid {
  transform-origin: 200px !important;
}

.pointer {
  width: 170px;
  position: absolute;
  z-index: 12000000;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vis-time-axis .vis-text {
  color: rgb(210, 210, 210) !important;
  transform-origin: 50% !important;
}

.vis-panel.vis-top {
  transform-origin: 200px !important;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border: 0 #bfbfbf !important;
}

div {
  display: block;
}

.vis-time-axis .vis-minor {
  padding-right: 3px;
  padding-bottom: 3px;
  padding-left: 4px !important;
  margin-top: -10px;
}

.vis-current-time {
  background-color: transparent !important;
  width: 0 !important;
  z-index: 0 !important;
  border-right: 10000px solid #a6ffa66b !important;
  border-left: 2px solid #37ff00 !important;
}
