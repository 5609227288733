@font-face {
  font-family: OpenSans;
  font-weight: 300;
  src: url('/src/assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 400;
  src: url('/src/assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 600;
  src: url('/src/assets/fonts/OpenSans/OpenSans-SemiBold.ttf')
    format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 700;
  src: url('/src/assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 800;
  src: url('/src/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf')
    format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: 300;
  font-style: italic;
  src: url('/src/assets/fonts/OpenSans/OpenSans-LightItalic.ttf')
    format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 400;
  font-style: italic;
  src: url('/src/assets/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 600;
  font-style: italic;
  src: url('/src/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf')
    format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 700;
  font-style: italic;
  src: url('/src/assets/fonts/OpenSans/OpenSans-BoldItalic.ttf')
    format('truetype');
}
@font-face {
  font-family: OpenSans;
  font-weight: 800;
  font-style: italic;
  src: url('/src/assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf')
    format('truetype');
}
