.form-wrapper {
  @apply mb-2;
}

.form-control {
  @apply border border-solid outline-none;
}

.form-control.theme-primary {
  @apply bg-primary border-primary text-white;
}

.form-control.size-sm {
  @apply text-sm px-4 py-0.5;
}

.form-control.is-invalid {
  @apply border-danger;
}

.form-control ~ .helper-text {
  @apply text-xs;
}

.form-control.is-invalid ~ .helper-text {
  @apply text-danger;
}
