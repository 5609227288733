@import "react-toastify/dist/ReactToastify.min.css";
@import url('../src/assets/styles/index.scss');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
