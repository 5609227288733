.spotter-swal2 {
  .swal2 {
    &-input {
      @apply bg-primary text-white;
    }

    &-popup {
      @apply bg-primary-100 shadow-lg;
    }

    &-title {
      @apply text-white text-md;
    }
  }

  .swal2-styled {
    @apply px-6 py-2;

    &.swal2-confirm {
      @apply bg-secondary text-sm;
    }

    &.swal2-cancel {
      @apply text-sm;
    }
  }

}
