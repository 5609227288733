label {
  cursor: pointer;
}

label:before {
  content: '';
  width: 15px;
  height: 15px;
  background: #000000;
  display: flex;
  border-radius: 100%;
}

input:hover ~ label:before {
  background: url('/src/assets/icons/label-tick.svg') no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  margin-left: -2px;
}

[type='checkbox']:checked ~ label:before {
  background: url('/src/assets/icons/label-tick.svg') no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  margin-left: -2px;
}

label {
  cursor: pointer;
}

label::before {
  content: '';
  width: 15px;
  height: 15px;
  background: #000000;
  display: flex;
  border-radius: 100%;
}

input:hover ~ label:before {
  background: url('/src/assets/icons/label-tick.svg') no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  margin-left: -2px;
}

[type='checkbox']:checked ~ label:before {
  background: url('/src/assets/icons/label-tick.svg') no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  margin-left: -2px;
}
