.controller {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5%;
  position: absolute;
  top: 100%;
  transform: translate(0%, -88%);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(99, 99, 99, 0) 100%,
    transparent 100%
  );
}

#button2,
.button7 {
  margin-right: 30px;
}
​
#button2 {
  margin-left: 5px;
}
​
.button4 {
  transform: rotate(180deg);
  margin-left: 10px;
  margin-right: 10px;
  margin-left: 42px;
}
​
button {
  background-color: transparent;
  border: 0px solid rgb(255, 255, 255);
  cursor: pointer;
}
​
.button6 {
  margin-right: 25px;
  margin-left: 10px;
  position: flex;
}
​
.pin {
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  transform: rotate(0deg);
  margin-right: 10px;
}
​
svg:hover {
  -webkit-filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
  filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
}
​
#circle {
  background: transparent;
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 36px;
  padding-top: 4px;
  padding-left: 6px;
}
​
.div6 {
  position: relative;
  top: 0;
  right: 0;
  margin-top: -10px;
}
​
input[type="checkbox"] {
  display: none;
}
​
svg {
  cursor: pointer;
}
​
svg path {
  fill: rgb(242, 242, 242);
}
​
.button4 svg path {
  fill: rgba(121, 121, 121, 0.675);
}
​
.reallife svg path {
  fill: rgba(121, 121, 121, 0.675);
}
​
[type="checkbox"]:checked+.label svg path {
  fill: rgb(92, 200, 80);
}
​
[type="checkbox"]:checked+.label4 svg path {
  fill: rgba(121, 121, 121, 0.675);
}
/* [type="checkbox"]:checked + #label1 svg path{
  animation: myfirst 2s 1; 
} */
​
#button1:active label svg path {
  fill: rgb(92, 200, 80);
}
​
#button2:active label svg path {
  fill: rgb(92, 200, 80);
}
​
label:before {
  content: '';
  width: 0px !important;
  height: 0px !important;
  background: transparent !important;
}
​
​
input:hover~label:before {
  background: none !important;
  width: 0px !important;
  height: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}
​
[type='checkbox']:checked~label:before {
  background: none !important;;
  background-size: contain;
  width: 0px !important;
  height: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}
